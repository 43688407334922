





































import { Component, Vue } from 'vue-property-decorator'
import AuthService from '@/services/AuthService'
import store from '@/store'
import Auth from '@/store/modules/Auth'
import Setting from '@/store/modules/Setting'
import { getModule } from 'vuex-module-decorators'
import UserService from '@/services/UserService'
import UserInfo from '@/store/modules/UserInfo'
import SettingService from '@/services/SettingService'
import axios from 'axios'
import {
  getUnreadCount,
  getLikeCount,
  getFavouriteCount
} from '@/helpers/GetNotificationCount'

const SettingModule = getModule(Setting, store)
const AuthModule = getModule(Auth, store)
const UserModule = getModule(UserInfo, store)

@Component
export default class LineLogin extends Vue {
  private callback_url: any = window.location.origin + '/line-login/'
  private loginLineError: boolean = false

  created() {
    this.init()
  }

  init() {
    const data = new URLSearchParams()
    data.append('grant_type', 'authorization_code')
    data.append('code', this.$route.query.code as string)
    data.append('redirect_uri', this.callback_url)
    data.append('client_id', process.env.VUE_APP_LINE_CLIENT_ID)
    data.append('client_secret', process.env.VUE_APP_LINE_CLIENT_SECRET)
    axios
      .post('https://api.line.me/oauth2/v2.1/token', data)
      .then(response => {
        this.loginWithLine(response.data.id_token)
      })
      .catch(() => {
        this.loginLineError = true
      })
  }

  loginWithLine(id_token: string) {
    let data = {
      id_token: id_token
    }
    AuthService.loginWithLine(data)
      .then(response => {
        if (response.status === 200) {
          let token = response.data.access_token
          let user_id = response.data.user_id
          AuthModule.SET_NEW_TOKEN(token)
          UserModule.HANDLE_USER_ID(user_id)
          // if (token) {
          //   this.checkUserBelongToGroup()
          // }
        }
      })
      .catch(() => {
        // if (error.response.status === 401) {
        //   if (error.response.data.message === 'NOT_HAVE_EMAIL_TO_LOGIN') {
        //     this.modalMess = this.$t('common.message.not_have_email_to_login') as string
        //     this.$bvModal.show('modal-error')
        //   } else if (
        //     error.response.data.message === 'NOT_FOUND_EMAIL_IN_SYSTEM'
        //   ) {
        //     this.modalMess = this.$t('common.message.not_found_email_in_system') as string
        //     this.$bvModal.show('modal-error')
        //   }
        // }
        this.loginLineError = true
      })
      .finally(() => this.handleRedirect())
  }

  handleRedirect() {
    AuthModule.SET_REDIRECT_LOGIN_WITH_LINE('')
  }

  checkUserBelongToGroup() {
    let path = '/'
    if (this.$store.state.auth.redirectLoginWithLine) {
      path = this.$store.state.auth.redirectLoginWithLine
    } else if (this.$route.query.redirect) {
      path = this.$route.query.redirect as string
    }
    AuthService.checkUserBelongToGroup()
      .then(response => {
        if (response.status === 200) {
          this.$router.push({ path: path })
          this.resetStoreToDefault()
          this.getUserInfo()
          this.getUserSetting()
          this.getUserProfile()

          //call nav_header count
          getUnreadCount()
          getLikeCount()
          getFavouriteCount()
        }
      })
      .catch(error => {
        if (error.response.data.is_request_join_group) {
          this.$bvModal.show('modal-info-login')
        } else {
          this.$router.push({ name: 'employee-without-group' })
          console.log(error)
        }
      })
  }

  resetStoreToDefault() {
    UserModule.RESET_DEFAULT()
    SettingModule.RESET_DEFAULT()
  }

  getUserInfo() {
    UserService.getUserInfo()
      .then(response => {
        if (response.status === 200) {
          UserModule.HANDLE_USER_INFO(response.data)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  getUserProfile() {
    UserService.getUserProfile()
      .then(response => {
        if (response.status === 200) {
          UserModule.HANDLE_USER_PROFILE(response.data)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  getUserSetting() {
    SettingService.getUserSetting()
      .then(response => {
        if (response.status === 200) {
          SettingModule.HANDLE_USER_SETTING(response.data)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  jumpToLogin() {
    this.$router.push({ name: 'login' })
  }
}
